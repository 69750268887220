@charset "utf-8";

@import "minimal-mistakes/skins/dirt"; // skin
@import "minimal-mistakes"; // main partials

.author__bio {
    margin: 0;
    font-size: 0.9em;
    font-style: italic;

    @include breakpoint($large) {
      margin-top: 10px;
      margin-bottom: 20px;
    }

  }

  .author__avatar img {
    max-width: 200px;
    border-radius: 0%;

    @include breakpoint($large) {
      padding: 0px;
      border: 0px solid $border-color;
    }
  }
